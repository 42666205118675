<template>
    <div>
        <div class="box">
            <b-tabs
                v-model="activeTab"
                @input="
                    value => {
                        openOnce[value] = true
                    }
                "
            >
                <b-tab-item
                    :label="tab.label"
                    :icon="tab.icon"
                    :value="tab.type"
                    v-for="tab in tabs"
                    :key="`tab_${tab.index}`"
                    :disabled="tab.disabled"
                >
                    <PreordersTable
                        :type="tab.type"
                        :status="tab.status"
                        v-if="openOnce[tab.index]"
                        @updateTabs="openOnce = { [tab.index]: true }"
                    />
                </b-tab-item>
            </b-tabs>
        </div>
        <Toaster ref="toaster" />
    </div>
</template>

<script>
    import PreordersTable from '@/components/Preorder/PreordersTable'

    import Toaster from '@/components/UI/Toaster'
    import { mapState } from 'vuex'

    export default {
        name: 'Precommande',
        title: 'layout.preorders',
        components: {
            Toaster,
            PreordersTable
        },
        data: function () {
            return {
                activeTab: 0,
                counts: null,
                openOnce: {
                    0: true
                }
            }
        },
        computed: {
            ...mapState({
                projectId: state => state.project.id
            }),
            ...mapState('client_preorders', {
                preorders: state => state.preorders,
                config: state => state.preordersConfig
            }),
            project() {
                return this.$store.state.project
            },
            tabs() {
                return [
                    {
                        type: 'pending',
                        label: this.$t('precommande.status.encours') + ` (${this.preorders?.counts?.pending || 0})`,
                        icon: 'feather',
                        index: 0,
                        disabled: !this.preorders?.counts?.pending
                    },
                    {
                        type: 'expired',
                        label: this.$t('precommande.status.done') + ` (${this.preorders?.counts?.expired || 0})`,
                        icon: 'ban',
                        index: 1,
                        disabled: !this.preorders?.counts?.expired
                    }
                ]
            }
        }
    }
</script>
